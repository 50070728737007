.button-container {
    margin: 1.125em 4.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #E4BB90;
    border-radius: 36px;
    padding: 0.5em 0;
    gap: 0.75em;
}

.button-container:hover {
    background-color: #E4BB90;
    h2 {
        color: #FFFFFF;
    }
}

.button-container h2 {
    color: #E4BB90;
    font-size: 26px;
    font-weight: 500;
}


.button-container img:hover {
    /* content: url(${image}); */
}

@media screen and (min-width: 768px) {
    .button-container {
        border: 3px solid #E4BB90;
        padding: 1.5em 0;
        border-radius: 56px;
        gap: 5em;
    }

    .button-container h2 {
        font-size: 36px;
        font-weight: 500;
    }
}