.footer-container {
    display: flex;
    background-color: #E4BB90;
    color: #FFFFFF;
    padding: 2em;
    gap: 1.5em;
}

.footer-container h2 {
    font-size: 12px;
    font-weight: 700;
    padding-bottom: 0.5em;
}


.footer-container p {
    font-size: 10px;
}

.footer-container a {
    color: #FFFFFF;
}

.footer-left-container {
    display: flex;
    flex-direction: column;
    justify-content: left;
    width: 50%;
}

.footer-links-container {
    padding-bottom: 1em;
}

.footer-links-container a {
    display: flex;
    flex-direction: column;
    font-size: 10px;
}


.footer-links-container a:hover {
    color: grey;
}

.footer-atendimento-container {
    padding-bottom: 1em;
}

.footer-produtos-container {
    padding-bottom: 1em;
}

.footer-contatos-container {}

.footer-contatos-container a {
    display: flex;
    align-items: center;
}

.footer-contatos-container a:hover {
    color: grey;
}

.footer-right-container {
    display: flex;
    flex-direction: column;
    justify-content: right;
    width: 50%;
}

.footer-logo-container {
    display: flex;
    justify-content: center;
}

.footer-logo-container img {
    width: 60%;
    padding-bottom: 1em;
}

.footer-redes-container {
    padding-bottom: 1em;
}

.footer-redes-container img {
    width: 20%;
}

.footer-pagamento-container {
    padding-bottom: 1em;
}

.footer-pagamento-container div {
    align-items: center;
    display: flex;
}

.footer-pagamento-container img {
    width: 20%;
}

@media screen and (min-width: 768px) {
    .footer-container {
        padding: 5em;
        gap: 10em;
    }

    .footer-container h2 {
        font-size: 28px;
        font-weight: 500;
    }

    .footer-container p {
        font-size: 20px;
    }

    .footer-links-container a {
        font-size: 20px;
        margin-bottom: 0.15em;
    }

    .footer-logo-container {
        justify-content: left;
        margin-left: 3.5em;
    }

    .footer-logo-container img {
        content: url("../../assets/footer-gbgesso-desktop-logo.png");
        width: 40%;
        height: auto;
        margin: 24px 0;
    }

    .footer-redes-container img {
        width: 3em;
    }

    .footer-pagamento-container img {
        width: 10%;
    }
}