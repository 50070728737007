.header-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 1em;
}


.header-container a {
    text-decoration: none;
    color: black;
}

.header-container h1 {
    font-size: 22px;
    font-weight: 600;
    padding: 0;
    margin: 0;
    white-space: nowrap;
}

.header-container h2 {
    font-size: 10px;
    text-align: center;
    padding: 0;
    margin: 0;
    white-space: nowrap;
}

.header-top-container {
    justify-content: space-evenly;
    display: flex;
    padding: 1.75em 0.75em;
}

.header-left-container {
    display: flex;
    align-items: center;
}

.header-left-container img {
    width: 25%;
}


.header-right-container {
    display: flex;
    width: 40%;
    align-items: center;
}

.header-right-container img {
    width: 90%;
}


.header-bottom-container {
    display: flex;
    background-color: #E4BB90;
    padding: 0.5em 0;
    color: #FFFFFF;
    justify-content: center;
    font-size: 1.75vw;
    /* Fonte adaptável para o tamanho da viewport */
    white-space: nowrap;
    /* Impede quebra de linha */
    width: auto;
}

@media screen and (min-width: 768px) {
    .header-container {
        margin: 0;
    }

    .header-container h1 {
        font-size: 36px;
        text-align: center;
    }

    .header-container h2 {
        font-size: 22px;
    }

    .header-top-container {
        padding: 2.5em;
        justify-content: space-between;
    }

    .header-left-container {
        width: 75%;
    }

    .header-right-container {
        justify-content: right;
        gap: 1em;
    }

    .header-right-container img {
        width: 100%;
    }

    .header-bottom-container {
        margin-bottom: 1em;
    }
}