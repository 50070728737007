.about-page-container {
    display: flex;
    flex-direction: column;
    color: #C68334;
    font-size: 14px;
}

.about-page-banner-container {
    display: flex;
    align-items: center;
    padding: 1em;
    gap: 0.75em;
}

.about-page-banner-container img {
    width: 20%;
}

.about-page-middle-container {
    font-size: 18px;
    text-align: center;
    padding: 1.25em;
    margin: 3.75em 0;
    background-image: url('../assets/about-page-banner-5-img.png');
}

.about-page-bottom-container {
    justify-content: center;
    align-items: center;
}

.about-page-bottom-container img {
    width: 100%;
    object-fit: cover;
}

.about-page-bottom-container .swiper-button-next,
.about-page-bottom-container .swiper-button-prev {
    color: #E4BB90;
}

@media screen and (min-width: 768px) {
    .about-page-container p {
        text-align: left;
    }

    .about-page-top-container {
        padding: 0 8em;
        margin: 5em 16em;
    }

    .about-page-banner-container img {
        width: 10%;
    }

    .about-page-banner-container p {
        font-size: 32px;
        /* text-align: center; */
        width: 100%;
    }


    .about-page-middle-container {
        font-size: 36px;
    }

    .about-page-bottom-container {
        margin: 10em 0;
    }

    .about-page-bottom-container img {
        justify-content: center;
        /* margin: 0 15em; */
    }

    .about-carousel-image-1 {
        content: url("../assets/aboutPage-carousel-desktop-1-img.png");
    }

    .about-carousel-image-2 {
        content: url("../assets/aboutPage-carousel-desktop-2-img.png");
    }

    .about-carousel-image-3 {
        content: url("../assets/aboutPage-carousel-desktop-3-img.png");
    }

    .about-carousel-image-4 {
        content: url("../assets/aboutPage-carousel-desktop-4-img.png");
    }
}