.contact-card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border: 2px solid #E4BB90;
    margin: 1em 5em;
    padding: 1em;
}

.contact-card-image-container img {
    width: 60%;
}

.contact-card-text-container p {
    font-size: 12px;
    margin: 0.75em 0;
}

.contact-card-text-container h2 {
    font-size: 14px;
    color: #FFFFFF;
    background-color: #E4BB90;
    padding: 0.75em 0;
    border-radius: 24px;
}   

@media screen and (min-width: 768px) {
    .contact-card-container {
        margin: 2em 40em;
    }

    .contact-card-image-container img {
        width: 75%;
    }

    .contact-card-text-container p {
        font-size: 24px;
    }

    .contact-card-text-container h2 {
        font-size: 30px;
    }
}