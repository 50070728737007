.contact-page-container {
    display: flex;
    flex-direction: column;
    color: #C68334;
}

.contact-page-title-container {
    display: flex;
    background-image: url(../assets/contactPage-banner-img.png);
    padding: 3em 1.5em;
    margin: 1.5em 0;
}

.contact-page-title-container h2 {
    text-align: center;
    width: 100%;
}

.contact-page-card-container {
    display: flex;
    flex-direction: column;
}

@media screen and (min-width: 768px) {
    .contact-page-card-container {

    }

    .contact-page-title-container {

    }

    .contact-page-title-container h2 {
        font-size: 32px;
    }

    .contact-page-card-container {

    }
}