.card-container {
    gap: 1em;
    display: flex;
    justify-content: space-around;
    color: #E4BB90;
    margin: 2em;
    text-align: center;
}

.card-container h2 {
    font-size: 18px;
    font-weight: 600;
}

.card-container p {
    font-size: 10px;
    margin: 0.5em 0;
}

.card-left-container {}

.card-left-container img {
    width: 9.375em;
    height: 9.375em;
    border: 2px solid #E4BB90;
}

.card-right-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.card-right-top-container {}

.card-right-bottom-container {}

.card-right-bottom-container h2 {
    color: #FFFFFF;
    font-size: 20px;
    background-color: #E4BB90;
    border: 2px solid #E4BB90;
    border-radius: 20px;
    margin: 0 1em;
}

@media screen and (min-width: 768px) {
    .card-container {
        gap: 2em;
    }

    .card-left-container {}

    .card-left-container img {
        width: 20em;
        height: 20em;
    }

    .card-right-container {}

    .card-right-top-container {}

    .card-right-top-container h2 {
        font-size: 26px;
        font-weight: 500;
    }

    .card-right-top-container p {
        font-size: 20px;
    }

    .card-right-bottom-container {}

    .card-right-bottom-container p {
        font-size: 26px;
    }

    .card-right-bottom-container h2 {
        font-size: 32px;
        font-weight: 500;
        padding: 0.75em;
        border-radius: 50px;
    }
}