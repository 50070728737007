.products-page-container {
    display: flex;
    flex-direction: column;
    color: #E4BB90;
    align-items: center;
}

.products-page-title-container {
    display: flex;
    align-items: center;
}

.products-page-cards-container {}

.products-page-services-top-container {
    display: flex;
    flex-direction: column;
    padding-top: 1em;
}

.products-page-services-bottom-container {
    display: flex;
    flex-direction: column;
    padding-top: 1em;
}

.products-page-services-title-container {
    text-align: center;
    font-size: 28px;
    color: #FFFFFF;
    background-color: #E4BB90;
    border-radius: 50px;
    padding: 0.625em 0;
}

.products-page-services-title-container h2 {
    padding: 0 1em;
}

.products-page-service-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1em;
}

.products-page-service-container img {
    width: 3.125em;
    height: 3.125em;
}

@media screen and (min-width: 768px) {
    .products-page-container {}

    .products-page-title-container {
        gap: 0.5em;
    }

    .products-page-title-container img {}

    .products-page-title-container h2 {
        font-size: 36px;
    }

    .products-page-cards-container {
        display: flex;
        gap: 5em;
    }

    .products-page-services-top-container {
        margin-bottom: 3em;
    }

    .products-page-services-top-container h2 {
        font-size: 42px;
    }

    .products-page-service-container {
        margin: 1.5em;
    }

    .products-page-service-container img {
        width: 5em;
        height: 5em;
    }

    .products-page-service-container h3 {
        font-size: 20px;
    }

    .products-page-services-bottom-container h2 {
        font-size: 42px;
    }
}