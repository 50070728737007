.faq-container {
    display: flex;
    flex-direction: column;
    margin: 1.5em 0;
}

.faq-button-container {
    display: flex;
    align-items: center;
    justify-content: right;
    margin-right: 1em;
    color: #E4BB90;
}

.faq-button-container img {
    width: 5%;
}

.faq-list-container {
    padding: 1em;
}

.faq-list-container h2 {
    display: flex;
    align-items: center;
    gap: 12px;
}

.faq-list-item-container {
    margin: 1em 0;
}

.faq-list-item-container h2 {
    color: #C68334;
    font-size: 14px;
}

.faq-list-item-container p {
    font-size: 14px;
}

.faq-list-item-paragraph-container {
    color: #E4BB90;
    font-size: 20px;
}

@media screen and (min-width: 768px) {
    .faq-button-container {
        gap: 0.5em;
    }

    .faq-button-container:hover {
        cursor: pointer;
    } 

    .faq-container h2 {
        font-size: 24px;
    }

    .faq-container p {
        font-size: 24px;
    }

    .faq-button-container img {
        width: 2%;
        height: auto;
    }

    .faq-list-container {
        margin: 0 36px;
    }

    .faq-list-container li {
        margin: 12px 0;
    }

    .faq-list-container li h2:hover {
        cursor: pointer;
        color: #E4BB9F;
    }

    .faq-list-container h2 {
        display: flex;
        align-items: center;
        gap: 12px;
    }
}