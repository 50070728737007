.homepage-container {
    width: 100%;
    justify-content: center;
    align-items: center;
}

.homepage-swiper-container {}

.homepage-buttons-container {
    padding: 4em 0;
}

.image-container img {
    width: 100%;
    object-fit: cover;
}

.homepage-swiper-container .swiper-button-next,
.homepage-swiper-container .swiper-button-prev {
    color: #E4BB90;
}

@media screen and (min-width: 768px) {
    .homepage-buttons-container {
        margin: 0 36em;
    }
    
    .carousel-image-1 {
        content: url('../assets/header-carousel-desktop-1-img.png');
    }

    .carousel-image-2 {
        content: url('../assets/header-carousel-desktop-2-img.png');
    }

    .carousel-image-3 {
        content: url('../assets/header-carousel-desktop-3-img.png');
    }
    
    .carousel-image-4 {
        content: url('../assets/header-carousel-desktop-4-img.png');
    }
}